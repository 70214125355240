import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';

interface ToggleGroup {
  title: string;
  url: string;
}

@Component({
  selector: 'app-toggle-group',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, RouterModule],
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss'],
})
export class ToggleGroupComponent {
  @Input() toggleGroup!: ToggleGroup[];
}
