import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfirmComponent } from './confirm.component';
import { Confirm } from './confirm.service';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [CommonModule],
  providers: [Confirm],
  exports: [ConfirmComponent],
})
export class ConfirmModule {}
