<div class="sidebar pt-5 mt-3" *ngIf="this.userTypeId !== 'MASTER'">
  <!-- <div class="search_box sidebar_search d-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.5 0C8.53757 0 11 2.46243 11 5.5C11 6.83879 10.5217 8.06586 9.72656 9.01962L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.68 14.0271 13.4106 14.0464 13.2157 13.9114L13.1464 13.8536L9.01962 9.72656C8.06586 10.5217 6.83879 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0ZM5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1Z"
        fill="#667085"
      />
    </svg>
    <input matInput (keyup)="applyAllFilter($event)" placeholder="Search" />
  </div> -->
  <mat-nav-list class="mt-0">
    <a
      mat-list-item
      routerLinkActive="active-link"
      [routerLink]="['/']"
      class="outerNav"
    >
      <svg
        class="me-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.5495 2.53189C11.3874 1.82531 12.6126 1.82531 13.4505 2.5319L20.2005 8.224C20.7074 8.65152 21 9.2809 21 9.94406L21 19.2539C21 20.2204 20.2165 21.0039 19.25 21.0039H15.75C14.7835 21.0039 14 20.2204 14 19.2539L14 14.2468C14 14.1088 13.8881 13.9968 13.75 13.9968H10.25C10.1119 13.9968 9.99999 14.1088 9.99999 14.2468L9.99999 19.2539C9.99999 20.2204 9.2165 21.0039 8.25 21.0039H4.75C3.7835 21.0039 3 20.2204 3 19.2539V9.94406C3 9.2809 3.29255 8.65152 3.79952 8.224L10.5495 2.53189ZM12.4835 3.6786C12.2042 3.44307 11.7958 3.44307 11.5165 3.6786L4.76651 9.37071C4.59752 9.51321 4.5 9.72301 4.5 9.94406L4.5 19.2539C4.5 19.392 4.61193 19.5039 4.75 19.5039H8.25C8.38807 19.5039 8.49999 19.392 8.49999 19.2539L8.49999 14.2468C8.49999 13.2803 9.2835 12.4968 10.25 12.4968H13.75C14.7165 12.4968 15.5 13.2803 15.5 14.2468L15.5 19.2539C15.5 19.392 15.6119 19.5039 15.75 19.5039H19.25C19.3881 19.5039 19.5 19.392 19.5 19.2539L19.5 9.94406C19.5 9.72301 19.4025 9.51321 19.2335 9.37071L12.4835 3.6786Z"
          fill="#C2CCD6"
        />
      </svg>
      Home
    </a>
  </mat-nav-list>
  <mat-nav-list class="mt-0">
    <a
      mat-list-item
      routerLinkActive="active-link"
      [routerLink]="['/services/all']"
      [queryParams]="{ d: 'true' }"
      class="outerNav"
    >
      <svg
        class="me-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V13C2 14.1046 2.89543 15 4 15H15C16.1046 15 17 14.1046 17 13V6C17 4.89543 16.1046 4 15 4H4ZM6.50001 17.5C5.56809 17.5 4.78504 16.8626 4.56302 16H15.5C16.8807 16 18 14.8807 18 13.5V6.56299C18.8626 6.78501 19.5 7.56806 19.5 8.49998V13.5C19.5 15.7091 17.7091 17.5 15.5 17.5H6.50001ZM9.00001 20C8.06809 20 7.28504 19.3626 7.06302 18.5H16.25C18.5972 18.5 20.5 16.5972 20.5 14.25V9.06299C21.3626 9.28501 22 10.0681 22 11V14.25C22 17.4256 19.4256 20 16.25 20H9.00001Z"
          fill="white"
        />
      </svg>
      Services
    </a>
  </mat-nav-list>
  <mat-nav-list class="mt-0">
    <a
      mat-list-item
      routerLinkActive="active-link"
      [routerLink]="['/help-support/opened']"
      [queryParams]="{ d: 'true' }"
      class="outerNav"
    >
      <svg
        class="me-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 18.5C11.5858 18.5 11.25 18.8358 11.25 19.25C11.25 19.6642 11.5858 20 12 20C12.4142 20 12.75 19.6642 12.75 19.25C12.75 18.8358 12.4142 18.5 12 18.5ZM17.5 10V9C17.5 5.96243 15.0376 3.5 12 3.5C8.96243 3.5 6.5 5.96243 6.5 9V10H9C9.55228 10 10 10.4477 10 11V15C10 15.5523 9.55228 16 9 16H6.5V16.25C6.5 17.4409 7.42516 18.4156 8.59595 18.4948L8.75 18.5L9.87812 18.4997C10.1871 17.626 11.0204 17 12 17C13.2426 17 14.25 18.0074 14.25 19.25C14.25 20.4926 13.2426 21.5 12 21.5C11.0201 21.5 10.1864 20.8735 9.87776 19.9992L8.75 20C6.74574 20 5.10873 18.4276 5.0052 16.4492L5 16.25V9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9V14C19 15.0544 18.1841 15.9182 17.1493 15.9945L17 16H15C14.4872 16 14.0645 15.614 14.0067 15.1166L14 15V11C14 10.4872 14.386 10.0645 14.8834 10.0067L15 10H17.5ZM8.5 11.5H6.5V14.5H8.5V11.5ZM17.5 11.5H15.5V14.5H17C17.2455 14.5 17.4496 14.3231 17.4919 14.0899L17.5 14V11.5Z"
          fill="#C2CCD6"
        />
      </svg>
      Help & Support
    </a>
  </mat-nav-list>
  <mat-nav-list class="mt-0">
    <a mat-list-item routerLinkActive="active-link" class="outerNav"
    routerLinkActive="active-link"
    [routerLink]="['/diagnostics']"
    [queryParams]="{ d: 'true' }"
    >
      <svg class="ms-1 me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
        <path d="M4 18.25C4 18.6642 4.33579 19 4.75 19H15.25L15.3518 18.9932C15.7178 18.9435 16 18.6297 16 18.25C16 17.8358 15.6642 17.5 15.25 17.5L13.499 17.4996V15.0017L17.7488 15.0019L17.9029 14.9967C19.0737 14.9176 19.9988 13.9428 19.9988 12.752V10.0003H15C14.4289 10.0003 13.8938 9.72173 13.5663 9.2539L13.3744 8.97973L11.5066 12.1406C11.1673 12.7148 10.5311 13.0462 9.86608 12.9951C9.20106 12.9441 8.62287 12.5195 8.37516 11.9002L6.90712 8.23017L6.5763 8.96709C6.29403 9.59583 5.669 10.0003 4.97979 10.0003H0V12.752L0.00519085 12.906C0.0843551 14.0768 1.05914 15.0019 2.25 15.0019L6.499 15.0017V17.4996L4.75 17.5L4.64823 17.5069C4.28215 17.5565 4 17.8703 4 18.25ZM7.998 15.0017H11.998L11.999 17.5H7.999L7.998 15.0017ZM19.9988 2.24995V6.50043H15.9112L14.6837 4.74685C14.3425 4.25947 13.7771 3.97879 13.1826 4.00169C12.5882 4.0246 12.046 4.34794 11.7434 4.86011L10.3034 7.29692L8.62484 3.10049C8.36398 2.44836 7.7385 2.01538 7.03627 2.00082C6.33405 1.98626 5.69115 2.39295 5.4035 3.03371L3.84716 6.50043H0V2.24995C0 1.05911 0.925161 0.0843532 2.09595 0.00519073L2.25 0H17.7488C18.9397 0 19.9145 0.92514 19.9936 2.0959L19.9988 2.24995ZM7.69636 3.47187C7.58456 3.19239 7.3165 3.00682 7.01555 3.00058C6.71459 2.99434 6.43907 3.16864 6.31578 3.44325L4.49438 7.50041H0.75C0.335786 7.50041 0 7.83619 0 8.25039C0 8.66459 0.335786 9.00037 0.75 9.00037H4.97979C5.27517 9.00037 5.54304 8.82701 5.66401 8.55754L6.9602 5.6703L9.30364 11.5289C9.4098 11.7943 9.6576 11.9762 9.94261 11.9981C10.2276 12.02 10.5003 11.8779 10.6457 11.6319L13.3033 7.13437L14.3856 8.68047C14.5259 8.88096 14.7553 9.00037 15 9.00037H19.25C19.6642 9.00037 20 8.66459 20 8.25039C20 7.83619 19.6642 7.50041 19.25 7.50041H15.3905L13.8644 5.32028C13.7182 5.1114 13.4759 4.99111 13.2211 5.00093C12.9664 5.01074 12.734 5.14932 12.6043 5.36882L10.13 9.55601L7.69636 3.47187Z" fill="white"/>
      </svg>
      Diagnostics
    </a>
  </mat-nav-list>
  <!-- <mat-nav-list class="mt-0">
    <a mat-list-item routerLinkActive="active-link" class="outerNav">
      <svg
        class="me-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.3135 15.4999C11.4859 14.9665 11.7253 14.4631 12.0219 13.9999H5.25278C4.01076 13.9999 3.00391 15.0068 3.00391 16.2488V16.8265C3.00391 17.7193 3.32242 18.5828 3.90219 19.2617C5.46849 21.0959 7.8545 22.0011 11.0004 22.0011C11.6005 22.0011 12.173 21.9681 12.7175 21.9021C12.3264 21.4774 11.9911 21.0004 11.7233 20.4829C11.4886 20.495 11.2476 20.5011 11.0004 20.5011C8.26206 20.5011 6.29618 19.7553 5.04287 18.2876C4.69502 17.8802 4.50391 17.3622 4.50391 16.8265V16.2488C4.50391 15.8352 4.83919 15.4999 5.25278 15.4999H11.3135ZM11.0004 2.00464C13.7618 2.00464 16.0004 4.24321 16.0004 7.00464C16.0004 9.76606 13.7618 12.0046 11.0004 12.0046C8.23894 12.0046 6.00036 9.76606 6.00036 7.00464C6.00036 4.24321 8.23894 2.00464 11.0004 2.00464ZM11.0004 3.50464C9.06737 3.50464 7.50036 5.07164 7.50036 7.00464C7.50036 8.93764 9.06737 10.5046 11.0004 10.5046C12.9334 10.5046 14.5004 8.93764 14.5004 7.00464C14.5004 5.07164 12.9334 3.50464 11.0004 3.50464ZM23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12C20.5376 12 23 14.4624 23 17.5ZM13.5 17.5C13.5 19.7092 15.2909 21.5 17.5 21.5C19.7091 21.5 21.5 19.7092 21.5 17.5C21.5 15.2909 19.7091 13.5 17.5 13.5C15.2909 13.5 13.5 15.2909 13.5 17.5ZM18.5 15.5C18.5 16.0523 18.0523 16.5 17.5 16.5C16.9477 16.5 16.5 16.0523 16.5 15.5C16.5 14.9477 16.9477 14.5 17.5 14.5C18.0523 14.5 18.5 14.9477 18.5 15.5ZM18.25 18C18.25 17.5858 17.9142 17.25 17.5 17.25C17.0858 17.25 16.75 17.5858 16.75 18V19.75C16.75 20.1642 17.0858 20.5 17.5 20.5C17.9142 20.5 18.25 20.1642 18.25 19.75V18Z"
          fill="#C2CCD6"
        />
      </svg>
      Account
    </a>
  </mat-nav-list> -->
</div>
<div class="sidebar pt-5 mt-3" *ngIf="this.userTypeId == 'MASTER'">
  <mat-nav-list class="mt-0">
    <a
      mat-list-item
      routerLinkActive="active-link"
      [routerLink]="['/master']"
      class="outerNav"
    >
      <svg
        class="me-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.5495 2.53189C11.3874 1.82531 12.6126 1.82531 13.4505 2.5319L20.2005 8.224C20.7074 8.65152 21 9.2809 21 9.94406L21 19.2539C21 20.2204 20.2165 21.0039 19.25 21.0039H15.75C14.7835 21.0039 14 20.2204 14 19.2539L14 14.2468C14 14.1088 13.8881 13.9968 13.75 13.9968H10.25C10.1119 13.9968 9.99999 14.1088 9.99999 14.2468L9.99999 19.2539C9.99999 20.2204 9.2165 21.0039 8.25 21.0039H4.75C3.7835 21.0039 3 20.2204 3 19.2539V9.94406C3 9.2809 3.29255 8.65152 3.79952 8.224L10.5495 2.53189ZM12.4835 3.6786C12.2042 3.44307 11.7958 3.44307 11.5165 3.6786L4.76651 9.37071C4.59752 9.51321 4.5 9.72301 4.5 9.94406L4.5 19.2539C4.5 19.392 4.61193 19.5039 4.75 19.5039H8.25C8.38807 19.5039 8.49999 19.392 8.49999 19.2539L8.49999 14.2468C8.49999 13.2803 9.2835 12.4968 10.25 12.4968H13.75C14.7165 12.4968 15.5 13.2803 15.5 14.2468L15.5 19.2539C15.5 19.392 15.6119 19.5039 15.75 19.5039H19.25C19.3881 19.5039 19.5 19.392 19.5 19.2539L19.5 9.94406C19.5 9.72301 19.4025 9.51321 19.2335 9.37071L12.4835 3.6786Z"
          fill="#C2CCD6"
        />
      </svg>
      Master List
    </a>
  </mat-nav-list>
  <mat-nav-list class="mt-0">
    <a
      mat-list-item
      routerLinkActive="active-link"
      [routerLink]="['/master/add-user']"
      [queryParams]="{ d: 'true' }"
      class="outerNav"
    >
      <svg
        class="me-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 4C2.89543 4 2 4.89543 2 6V13C2 14.1046 2.89543 15 4 15H15C16.1046 15 17 14.1046 17 13V6C17 4.89543 16.1046 4 15 4H4ZM6.50001 17.5C5.56809 17.5 4.78504 16.8626 4.56302 16H15.5C16.8807 16 18 14.8807 18 13.5V6.56299C18.8626 6.78501 19.5 7.56806 19.5 8.49998V13.5C19.5 15.7091 17.7091 17.5 15.5 17.5H6.50001ZM9.00001 20C8.06809 20 7.28504 19.3626 7.06302 18.5H16.25C18.5972 18.5 20.5 16.5972 20.5 14.25V9.06299C21.3626 9.28501 22 10.0681 22 11V14.25C22 17.4256 19.4256 20 16.25 20H9.00001Z"
          fill="white"
        />
      </svg>
      Add User
    </a>
  </mat-nav-list>
</div>