<!--  for user haven't login yet -->
<div class="top-head" *ngIf="!isAuthenticated && isLoaded">
  <div class="net-container">
    <div class="row">
      <div class="net l3 m3 s12">
        <div class="logo">
          <a routerLink="/">
            <img src="/assets/logo-b.png" class="header-logo" alt="vertel logo"
          /></a>
        </div>
      </div>
      <div class="net l9 m9 s12 net-text-right">
        <img
          src="/assets/svgs/building-connections.svg"
          alt="Building Connections"
        />
      </div>
    </div>
  </div>
</div>
<div class="header-bg" *ngIf="isLoaded">
  <div class="net-container">
    <div class="row">
      <header>
        <!-- user logged in -->
        <div class="header-two" *ngIf="isAuthenticated">
          <div class="net l3 m3 s12">
            <div class="logo">
              <a routerLink="/">
                <img
                  src="/assets/logo-b.png"
                  class="header-logo"
                  alt="vertel logo"
              /></a>
            </div>
          </div>
          <div class="net l9 m9 s12 net-text-right">
            <img
              src="/assets/svgs/building-connections.svg"
              alt="Building Connections"
            />
          </div>
        </div>
      </header>
    </div>
  </div>
</div>
