import { Component, Inject } from '@angular/core';
import { MasterService } from '../master.service';
import { pluck } from 'rxjs/operators';
import { Loader } from '@vertel/loader';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alert } from '@vertel/alert';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent {
  organizationData = [];
  organizationId!: string;
  service: any = [];
  selectedService: string | undefined;
  searchText: string = '';
  firstName!: string;
  lastName!: string;
  constructor(
    private masterService: MasterService,
    private loader: Loader,
    private alert: Alert,
    public dialogRef: MatDialogRef<UpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.masterService
      .getOrganizations()
      .pipe(pluck('data'))
      .subscribe((res: any) => {
        res.filter((item: any) =>
          this.service.push({
            name: item.name,
            _id: item._id,
          }),
        );
      });
    this.organizationData = this.service;
    this.firstName = this.data.firstName;
    this.lastName = this.data.lastName;
  }

  filterOptions(event: any) {
    this.loader.start();
    this.service = this.organizationData.filter((option: any) => {
      return option['name']
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
  }

  onOrganizationSelect() {
    this.searchText = '';
  }

  closeAlert() {
    location.reload();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  changeData() {
     const payload = {
       "firstName": this.firstName,
       "lastName": this.lastName,
       "organizations": this.selectedService
     };
     this.masterService.uesrActivateorDeactivate(payload, this.data.id).subscribe(
       (res: any) => {
         this.alert.present(res.message);
       },
       (error) => {
         this.alert.present(error.error.message);
       },
     );
     this.dialogRef.close();
  }

  onDropdownOpen() {
    this.searchText = '';  // Optionally reset search input when dropdown is opened
    this.service = [...this.organizationData];  // Reset filter when dropdown opens
  }
}