<dialog
  [open]="config.canShow"
  class="pop-up"
  [ngStyle]="{ display: config.canShow ? 'flex' : 'none' }"
>
  <section class="alert-container">
    <p>{{ config.data?.message }} <span>!</span></p>
    <button class="btn-blue-outline" (click)="onClose()">Ok</button>
  </section>
</dialog>
