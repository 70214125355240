<div class="sub-navBg" *ngIf="this.isLoaded">
  <div class="net-container">
    <nav>
      <div class="sub-nav-list">
        <a
          [routerLink]="['/services/all']"
          [queryParams]="{ d: 'true' }"
          routerLinkActive="active-link"
          *ngIf="controls.includes('SERVICE')"
        >
          Vertel services
        </a>
        <a
          routerLink="/help-support/opened"
          [queryParams]="{ d: 'true' }"
          routerLinkActive="active-link"
          *ngIf="controls.includes('TICKET')"
        >
          Ticket management
        </a>
        <a
          routerLink="/"
          routerLinkActive="active-link"
          *ngIf="controls.includes('BILLING')"
        >
          Billing management
        </a>

        <!-- <button>
          Service portals
          <ul class="menu-drop-down-list">
            <li>
              <a target="_blank" rel="noopener" [href]="graphUtilisationUrl"
                >NETWORK ACCESS UTILISATION GRAPHS</a
              >
            </li>
            <li>
              <a target="_blank" rel="noopener" [href]="sdWanUrl">SD-WAN</a>
            </li>
          </ul>
        </button> -->
      </div>
      <div class="sub-nav-button">
        <button class="portal-support" (click)="onPortalSupport()">
          Contact Us
        </button>
        <!-- <button class="btn-icon" aria-label="query your search">
          <svg>
            <use xlink:href="/assets/svgs/symbol.svg#magnifying_icon_two"></use>
          </svg>
          <ul class="menu-drop-down-list search-top">
            <li>
              <input type="text" placeholder="search..." />
            </li>
          </ul>
        </button> -->
        <!-- <button
          class="btn-icon"
          aria-label="toggle notification"
          (click)="toggleDropdown($event, 'notification')"
        >
          no need to remove now once confirm <span class="notification-alert">12</span>

          <svg>
            <use xlink:href="/assets/svgs/symbol.svg#bell_icon_two"></use>
          </svg>
          no need to remove now once confirm <div
            id="sub-menu-notification"
            class="notification menu-drop-down-list"
            *ngIf="isDropdownVisible && dropdownType === 'notification'"
          >
            <ul>
              <li><a routerLink="/" class="unread-notification"> Unread notification 1 </a></li>
              <li><a routerLink="/" class="unread-notification"> Unread notification 2</a></li>
              <li><a routerLink="/" class="read-notification"> Read notification 2</a></li>
            </ul>
          </div> -->
        <!-- </button> -->
        <button
          id="btn-sub-menu-setting"
          class="btn-icon"
          aria-label="toggle setting"
          (click)="toggleDropdown($event, 'setting')"
        >
          <svg class="setting-top">
            <use xlink:href="/assets/svgs/symbol.svg#icon_setting_two"></use>
          </svg>
          <div
            id="sub-menu-setting"
            class="setting menu-drop-down-list"
            *ngIf="isDropdownVisible && dropdownType === 'setting'"
          >
            <ul>
              <li><a routerLink="/account">Account Management</a></li>
              <li *ngIf="controls.includes('USER')">
                <a routerLink="/users">Users Management</a>
              </li>
              <li>
                <a
                  routerLink="/profile"
                  (click)="toggleDropdown($event, 'setting')"
                  >My Profile
                </a>
              </li>
              <li><button (click)="onLogout($event)">Log out</button></li>
            </ul>
          </div>
        </button>
      </div>
    </nav>
  </div>
</div>
