<mat-button-toggle-group name="fontStyle" aria-label="Font Style">
  <mat-button-toggle
    *ngFor="let toggleButton of toggleGroup"
    value="bold"
    title="{{ toggleButton.url }}"
    [routerLink]="[toggleButton.url]"
    queryParamsHandling="preserve"
    routerLinkActive="mat-button-toggle-checked"
    >{{ toggleButton.title }}
  </mat-button-toggle>
</mat-button-toggle-group>
