import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Confirm } from './confirm.service';
import { Config } from './confirm.type';

@Component({
  selector: 'vertel-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, OnDestroy {
  public config: Config = {
    canShow: false,
    data: {},
  };
  private subs = new Subscription();
  constructor(
    private confirm: Confirm,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.confirm.config$.subscribe((config) => {
        this.config = config;
        this.changeDetectorRef.detectChanges();
      }, console.error),
    );
  }

  public onClose(flag: boolean): void {
    this.confirm.close(flag);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
