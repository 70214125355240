<mat-toolbar color="primary">
  <button class="mobile-button d-block d-lg-none" mat-icon-button (click)="toggleHighlight()" [ngClass]="{'highlight': isHighlighted}">
    <span class="material-symbols-outlined menu-icon"> menu </span>
    <!-- <span class="material-symbols-outlined close-icon"> close </span> -->
  </button>
  <img routerLink="/" src="assets/logo.svg" alt="" class="logo-img" />
  <span class="title d-none d-lg-block ms-5">
    <svg routerLink="/" class="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
      fill="none">
      <path
        d="M8.9975 2.38811C9.56767 1.87584 10.4323 1.87584 11.0025 2.38811L16.5025 7.32965C16.8191 7.61414 17 8.01977 17 8.44544V15.4996C17 16.328 16.3284 16.9996 15.5 16.9996H13C12.1716 16.9996 11.5 16.328 11.5 15.4996V11.9996C11.5 11.7234 11.2761 11.4996 11 11.4996H9C8.72386 11.4996 8.5 11.7234 8.5 11.9996V15.4996C8.5 16.328 7.82843 16.9996 7 16.9996H4.5C3.67157 16.9996 3 16.328 3 15.4996V8.44544C3 8.01977 3.18086 7.61414 3.4975 7.32965L8.9975 2.38811ZM10.3342 3.13197C10.1441 2.96122 9.85589 2.96122 9.66583 3.13197L4.16583 8.07351C4.06029 8.16834 4 8.30355 4 8.44544V15.4996C4 15.7757 4.22386 15.9996 4.5 15.9996H7C7.27614 15.9996 7.5 15.7757 7.5 15.4996V11.9996C7.5 11.1711 8.17157 10.4996 9 10.4996H11C11.8284 10.4996 12.5 11.1711 12.5 11.9996V15.4996C12.5 15.7757 12.7239 15.9996 13 15.9996H15.5C15.7761 15.9996 16 15.7757 16 15.4996V8.44544C16 8.30355 15.9397 8.16834 15.8342 8.07351L10.3342 3.13197Z"
        fill="white" fill-opacity="0.75" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.21967 6.03033C6.92678 5.73744 6.92678 5.26256 7.21967 4.96967C7.51256 4.67678 7.98744 4.67678 8.28033 4.96967L12.7803 9.46967C13.0732 9.76256 13.0732 10.2374 12.7803 10.5303L8.28033 15.0303C7.98744 15.3232 7.51256 15.3232 7.21967 15.0303C6.92678 14.7374 6.92678 14.2626 7.21967 13.9697L11.1893 10L7.21967 6.03033Z"
        fill="white" fill-opacity="0.75" />
    </svg>
    <app-breadcrumb class="d-inline-block"></app-breadcrumb>
  </span>

  <span class="spacer"></span>
  <span class="welcome-text me-2 d-none">
    <span class="me-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4H18.75H5.25ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
          fill="#C2CCD6" />
      </svg>
    </span>
    <span class="me-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 1.99622C16.0499 1.99622 19.3567 5.19096 19.4958 9.24527L19.5 9.49622V13.5932L20.88 16.7492C20.949 16.9071 20.9847 17.0776 20.9847 17.25C20.9847 17.9403 20.425 18.5 19.7347 18.5L15 18.5015C15 20.1583 13.6568 21.5015 12 21.5015C10.4023 21.5015 9.09633 20.2526 9.00508 18.6778L8.99954 18.4992L4.27485 18.5C4.10351 18.5 3.93401 18.4648 3.77685 18.3965C3.14365 18.1215 2.8533 17.3852 3.12834 16.752L4.49999 13.5941V9.49611C4.50059 5.34132 7.85208 1.99622 12 1.99622ZM13.4995 18.4992L10.5 18.5015C10.5 19.3299 11.1716 20.0015 12 20.0015C12.7797 20.0015 13.4204 19.4066 13.4931 18.6459L13.4995 18.4992ZM12 3.49622C8.67983 3.49622 6.00047 6.17047 5.99999 9.49622V13.9058L4.65601 17H19.3525L18 13.9068L18.0001 9.50907L17.9964 9.28387C17.8853 6.0504 15.2416 3.49622 12 3.49622Z"
          fill="#C2CCD6" />
      </svg>
    </span>
  </span>
  <button class="profile-img" mat-icon-button [matMenuTriggerFor]="menu">
    {{ user.firstName.charAt(0) }}{{ user.lastName?.charAt(0) }}
  </button>
  <mat-menu #menu="matMenu" class="user-dropdown">
    <button mat-menu-item (click)="onLogout()">
      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
          stroke="#374151" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      </svg>
      <span class="ms-2">Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>