import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToasterComponent } from './toaster.component';
import { Toaster } from './toaster.service';

@NgModule({
  declarations: [ToasterComponent],
  imports: [CommonModule],
  providers: [Toaster],
  exports: [ToasterComponent],
})
export class ToasterModule {}
