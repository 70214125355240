import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalService } from '~services/global/global.service';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard {
  constructor(
    private globalService: GlobalService,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.globalService.acl$.value) {
      const flag = this.globalService.acl$.value.accessControls
        .map(({ module }) => module.control)
        .includes(route.data.control);
      if (!flag) {
        this.router.navigateByUrl('/').finally();
      }
      return flag;
    }
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.globalService.acl$.value) {
      const flag = this.globalService.acl$.value.accessControls
        .map(({ module }) => module.control)
        .includes(childRoute.data.control);
      if (!flag) {
        this.router.navigateByUrl('/').finally();
      }
      return flag;
    }
    return false;
  }
}
