import {
  ComponentFactoryResolver,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AnyType, Config } from './dialog.type';

@Injectable()
export class Dialog {
  public config$ = new BehaviorSubject<Config>({
    canShow: false,
    isCloseEnabled: false,
    data: {},
  });
  private viewContainerRef: ViewContainerRef | null = null;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  public set setContainerRef(ref: ViewContainerRef | null) {
    this.viewContainerRef = ref;
  }

  public show<Component>(
    component: Type<Component>,
    config?: {
      isCloseEnabled?: boolean;
      data?: AnyType;
    },
  ): Component {
    if (!this.viewContainerRef) {
      throw new Error('container not found');
    }

    this.config$.next({
      canShow: true,
      isCloseEnabled: config?.isCloseEnabled || false,
      data: {},
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.viewContainerRef.clear();

    const dynamicComponent =
      this.viewContainerRef.createComponent(componentFactory).instance;

    if ((dynamicComponent as AnyType).data) {
      (dynamicComponent as AnyType).data = config?.data || {};
    }

    document.body.style.overflow = 'hidden';
    return dynamicComponent;
  }

  public hide(): void {
    document.body.style.overflow = 'scroll';
    this.viewContainerRef?.clear();
    this.config$.next({ canShow: false, isCloseEnabled: true, data: {} });
  }
}
