import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';

import { OfflineModule } from '@ngx-pwa/offline';

import { DialogModule } from '@vertel/dialog';
import { ToasterModule } from '@vertel/toaster';
import { ConfirmModule } from '@vertel/confirm';
import { AlertModule } from '@vertel/alert';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '~environments/environment';

import { AUTH_INTERCEPTOR } from '~interceptors/auth/auth.interceptor';
import { LOG_INTERCEPTOR } from '~interceptors/log/log.interceptor';
import { ERROR_INTERCEPTOR } from '~interceptors/error/error.interceptor';

import { BasicHeaderComponent } from '~components/basic-header/basic-header.component';
import { UniversalFooterComponent } from '~components/universal-footer/universal-footer.component';
import { SubMenuComponent } from '~components/sub-menu/sub-menu.component';
import { PortalSupportComponent } from '~components/portal-support/portal-support.component';

import { BasicLayoutComponent } from '~layouts/basic-layout/basic-layout.component';
import { StandardLayoutComponent } from '~layouts/standard-layout/standard-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MasterModule } from '~pages/master/master.module';

@NgModule({
  declarations: [
    AppComponent,
    BasicHeaderComponent,
    UniversalFooterComponent,
    SubMenuComponent,
    PortalSupportComponent,
    BasicLayoutComponent,
    StandardLayoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.development,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    OfflineModule.forRoot({
      guardsRedirect: true,
      routeOffline: '/offline',
      routeUnavailable: '/unavailable',
    }),
    DialogModule,
    ToasterModule,
    ConfirmModule,
    AlertModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSidenavModule,
    FormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatPaginatorModule,
    MasterModule,
  ],
  providers: [AUTH_INTERCEPTOR, LOG_INTERCEPTOR, ERROR_INTERCEPTOR],
  bootstrap: [AppComponent],
})
export class AppModule {}
