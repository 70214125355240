import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ApiResWithData,
  AnyType,
  ApiRes,
  ListRespUsers,
} from '~interfaces/shared.interface';
import { UrlService } from '~services/url/url.service';

@Injectable()
export class UsersService extends UrlService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getUsers(query = '') {
    return this.httpClient.get<ListRespUsers>(this.usersURL + `?${query}`);
  }

  addUser(payload: {
    name: string;
    email: string;
    organizationId: string;
  }): Observable<ApiResWithData<null>> {
    return this.httpClient.post<ApiResWithData<null>>(this.usersURL, payload);
  }

  revokeAccess(payload: AnyType): Observable<ApiResWithData<any>> {
    return this.httpClient.put<ApiResWithData<any>>(
      this.revokeAccessURL,
      payload,
    );
  }

  removeAccess(payload: AnyType): Observable<ApiResWithData<any>> {
    return this.httpClient.patch<ApiResWithData<any>>(
      this.removeAccessURL,
      payload,
    );
  }

  resendAuthLinks(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.post<ApiRes>(this.resendAuthLinkURL, payload);
  }
}
