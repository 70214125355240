import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss'],
})
export class StandardLayoutComponent implements OnInit {
  userType!: string;
  value = 'MASTER';

  constructor(private router: Router) {}
  ngOnInit() {
    this.userType = localStorage.getItem('userTypeId') || '';
    this.value = JSON.stringify('MASTER');
    if (this.userType == this.value) {
      this.router.navigateByUrl('/master');
    }
  }
}
