import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Config } from './loader.type';

@Injectable()
export class Loader {
  public config$ = new BehaviorSubject<Config>({
    canShow: false,
    data: {},
  });
  public config = this.config$.asObservable();
  constructor() {}

  public start(message?: string): void {
    this.config$.next({ canShow: true, data: { message } });
    document.body.style.overflow = 'hidden';
  }

  public stop(): void {
    this.config$.next({ canShow: false, data: {} });
    document.body.style.overflow = 'scroll';
  }
}
