import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from './loader.type';
import { Loader } from './loader.service';

@Component({
  selector: 'vertel-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnDestroy {
  public config: Config = {
    canShow: false,
    data: {},
  };
  private subs = new Subscription();
  constructor(
    private loader: Loader,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.loader.config$.subscribe((config) => {
        this.config = config;
        this.changeDetectorRef.detectChanges();
      }, console.error),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
