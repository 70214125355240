import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '~services/auth/auth.service';
import { GlobalService } from '~services/global/global.service';
import { Acl } from '~interfaces/user.interface';
import { Alert } from '@vertel/alert';

@Injectable({
  providedIn: 'root',
})
export class GetAclResolver {
  constructor(
    private authService: AuthService,
    private globalService: GlobalService,
    private router:Router,
    private alert: Alert
  ) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state?: RouterStateSnapshot,
  ): Observable<Acl | null> {
    return this.authService.getAcl().pipe(
      map(({ data }) => {
        if (data) {
          this.globalService.setAcl = data;
          return data;
        } else {
          return null;
        }
      }),
      catchError((error) => {
        if(error.status === 401){
          this.alert.present(error.error.message);
          sessionStorage.clear();
          this.globalService.isLoggedIn$.next(false);
          this.router.navigate(['/login']);
        }
        return of(null);
      }),
    );
  }
}
