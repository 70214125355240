import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoaderComponent } from './loader.component';
import { Loader } from './loader.service';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule],
  providers: [Loader],
  exports: [LoaderComponent],
})
export class LoaderModule {}
