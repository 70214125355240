import { Injectable } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalService } from '~services/global/global.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard {
  constructor(
    private globalService: GlobalService,
    private router: Router,
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.globalService.isLoggedIn$.value) {
      this.router.navigate(['login']).finally();
    }
    return this.globalService.isLoggedIn$.value;
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.globalService.isLoggedIn$.value) {
      this.router.navigate(['login']).finally();
    }
    return this.globalService.isLoggedIn$.value;
  }

  canLoad(
    _route: Route,
    _segments: UrlSegment[],
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.globalService.isLoggedIn$.value) {
      this.router.navigate(['login']).finally();
    }
    return this.globalService.isLoggedIn$.value;
  }
}
