import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { AuthService } from '~services/auth/auth.service';
import { ICardsResp, IncidentElement } from '~interfaces/shared.interface';

@Component({
  selector: 'app-services-card',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss'],
})
export class ServicesCardComponent {
  cards!: ICardsResp;
  activeServices = 0;
  closedIncident = 0;
  openIncident = 0;
  constructor(authService: AuthService) {
    authService.getDashboardCards().subscribe({
      next: (result) => {
        this.cards = result;
        this.activeServices = this.getIncidentCountByStatus(
          this.cards.services.services,
          'Active',
        );
        const incidents = this.cards?.incident?.incident;
        if (incidents) {
          this.closedIncident = this.getIncidentCountByStatus(
            incidents,
            'Closed',
          );
          this.openIncident = this.getOpenTicketCountByStatus(incidents);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getIncidentCountByStatus(incidents: IncidentElement[], status: string) {
    return (
      incidents.find((incident: IncidentElement) => incident.status === status)
        ?.count ?? 0
    );
  }

  getOpenTicketCountByStatus(incidents: IncidentElement[]){
    let count = 0;
    incidents.forEach((res)=>{
      if(res.status != 'Closed')
        count += res.count
    })
    return count;
  }
}
