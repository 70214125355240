<section class="container-fluid">
  <h3 class="modal-title">Portal Support</h3>
  <div class="border-top"></div>
  <div class="modal-body resend-modal-content">
    <p>
      <strong>
        Please contact your organisation administrator<br />
        or the Vertel service desk</strong
      >
    </p>
    <span class="re-mail">
      <svg>
        <use xlink:href="/assets/svgs/symbol.svg#icon-service-man"></use>
      </svg>
    </span>
    <h3>Service desk</h3>
    <p>P: 1300 837 835 (1300 VERTEL)</p>
    <p>E: support&#64;vertel.com.au</p>
  </div>
</section>
