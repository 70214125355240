import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        let message: string;
        let statusText: string;
        statusText = e.statusText.replace(/ /g, '_').toUpperCase();
        switch (e.status) {
          case 400:
            message = e.error.message || 'you have send bad payload';
            break;
          case 401:
            message = e.error.message || 'login first to access resources';
            break;
          case 403:
            message = e.error.message || 'forbidden access resources';
            break;
          case 404:
            message = e.error.message || 'page not found';
            break;
          case 405:
            message =
              e.error.message ||
              'sorry report has been already submitted this month';
            break;
          case 422:
            message = e.error.message || 'invalid body send';
            break;
          case 500:
            message = 'server side error';
            break;
          case 0:
            if (navigator.onLine) {
              message = 'Internal Server Error';
              statusText =
                'We apologize for the inconvenience. Please try again later.';
            } else {
              statusText = 'OFFLINE';
              message =
                'no internet connection found. please check your network';
              console.error(statusText, message);
            }
            break;
          default:
            message = 'unhandled status code received';
            break;
        }
        return throwError(() => e);
      }),
    );
  }
}

export const ERROR_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
