import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '~services/url/url.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService extends UrlService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getOrganizations() {
    return this.httpClient.get(
      this.getOrganization + '?page=0&limit=0&sort={"name": 1}',
    );
  }

  createUserAdmin(payload = []) {
    return this.httpClient.post(this.userAdmin, payload);
  }

  uesrActivateorDeactivate(payload: any, id: string) {
    return this.httpClient.patch(this.userStatus + '/' + id, payload);
  }
}
