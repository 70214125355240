import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogComponent } from './dialog.component';
import { DialogDirective } from './dialog.directive';
import { Dialog } from './dialog.service';

@NgModule({
  declarations: [DialogComponent, DialogDirective],
  imports: [CommonModule],
  providers: [Dialog],
  exports: [DialogComponent, DialogDirective],
})
export class DialogModule {}
