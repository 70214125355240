import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Network } from '@ngx-pwa/offline';

import { Toaster } from '@vertel/toaster';

import { GlobalService } from '~services/global/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly title = 'vcp';
  subs = new Subscription();
  isShowConnectionStatus = false;
  isRedirectToOffline = true;

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private network: Network,
    private toaster: Toaster,
  ) {}

  ngOnInit(): void {
    this.initAppState();
    this.router.errorHandler = this.handleRoutingError.bind(this);

    /**
     * show toaster if connection status changed
     */
    this.subs.add(
      this.network.onlineChanges.subscribe((isOnline) => {
        if (this.isShowConnectionStatus) {
          const message = isOnline ? 'Connected' : 'Connection Lost!';
          this.toaster.show(message);
        } else {
          this.isShowConnectionStatus = true;
        }
      }),
    );
  }

  initAppState(): void {
    this.globalService.setAccessToken = this.globalService.getAccessToken;
    this.globalService.setAcl = this.globalService.getAcl;
    this.globalService.setUser = this.globalService.getUser;
  }

  /**
   * show toaster if it fail to load module.
   */
  handleRoutingError(error: any): any {
    if (error instanceof Error && error.name === 'ChunkLoadError') {
      this.toaster.show('Fail to Load!');
      if (this.isRedirectToOffline) {
        this.isRedirectToOffline = false;
        this.router
          .navigateByUrl('/offline')
          .then(() => (this.isRedirectToOffline = true));
      }
    }
    return error;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
