import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BasicLayoutComponent } from '~layouts/basic-layout/basic-layout.component';
import { StandardLayoutComponent } from '~layouts/standard-layout/standard-layout.component';

import { AuthorizedGuard } from '~guards/authorized/authorized-guard.service';
import { UnauthorizedGuard } from '~guards/unauthorized/unauthorized-guard.service';
import { RolesGuard } from '~guards/roles/roles.guard';

import { ResetPasswordResolver } from '~resolvers/reset-password/reset-password.resolver';
import { SetPasswordResolver } from '~resolvers/set-password/set-password.resolver';
import { GetAclResolver } from '~resolvers/get-acl/get-acl.resolver';

import { CustomPreloadingStrategyService } from '~services/custom-preloading-strategy/custom-preloading-strategy.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canLoad: [AuthorizedGuard],
    canActivate: [AuthorizedGuard],
    canActivateChild: [AuthorizedGuard],
    component: StandardLayoutComponent,
    loadChildren: () =>
      import('~pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    // For basic layout and unauthorized guard
    path: '',
    component: BasicLayoutComponent,
    canActivate: [UnauthorizedGuard],
    canActivateChild: [UnauthorizedGuard],
    canLoad: [UnauthorizedGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'set-password/:setToken',
        resolve: [SetPasswordResolver],
        loadChildren: () =>
          import('./pages/set-password/set-password.module').then(
            (m) => m.SetPasswordModule,
          ),
      },
      {
        path: 'reset-password/:resetToken',
        resolve: [ResetPasswordResolver],
        loadChildren: () =>
          import('./pages/reset-password/reset-password.module').then(
            (m) => m.ResetPasswordModule,
          ),
      },
    ],
  },
  {
    // For standard layout standard with authorized guard
    path: '',
    canLoad: [AuthorizedGuard],
    canActivate: [AuthorizedGuard],
    canActivateChild: [AuthorizedGuard],
    component: StandardLayoutComponent,
    children: [
      {
        path: 'to',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'users',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'USER' },
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'master',
        loadChildren: () =>
          import('./pages/master/master.module').then((m) => m.MasterModule),
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('./pages/change-password/change-password.module').then(
            (m) => m.ChangePasswordModule,
          ),
      },
      {
        path: 'services',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'SERVICE' },
        loadChildren: () =>
          import('./pages/services-vertel/services-vertel.module').then(
            (m) => m.ServicesVertelModule,
          ),
      },
      {
        path: 'help-support',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'SERVICE' },
        loadChildren: () =>
          import('./pages/help-support/help-support.module').then(
            (h) => h.HelpSupportModule,
          ),
      },
      {
        path: 'diagnostics',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'DIAGNOSTICS' },
        loadChildren: () =>
          import('./pages/diagnostics/diagnostics.module').then(
            (d) => d.DiagnosticsModule,
          ),
      },

      // {
      //   path: 'services',
      //   resolve: { acl: GetAclResolver },
      //   canActivate: [RolesGuard],
      //   data: { control: 'SERVICE' },
      //   loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesModule),
      // },
      {
        path: 'account',
        resolve: { acl: GetAclResolver },
        canLoad: [AuthorizedGuard],
        canActivate: [AuthorizedGuard, RolesGuard],
        canActivateChild: [AuthorizedGuard],
        data: { control: 'HELP' }, // TODO: update this as a ACCOUNT
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'billing',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'BILLING' },
        loadChildren: () =>
          import('./pages/billing/billing.module').then((m) => m.BillingModule),
      },
      {
        path: 'company-information',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'COMPANY_INFORMATION' },
        loadChildren: () =>
          import('./pages/company-information/company-information.module').then(
            (m) => m.CompanyInformationModule,
          ),
      },
      {
        path: 'tickets',
        resolve: { acl: GetAclResolver },
        canActivate: [RolesGuard],
        data: { control: 'TICKET' },
        loadChildren: () =>
          import('./pages/tickets/tickets.module').then((m) => m.TicketsModule),
      },
    ],
  },
  // For with basic layout and without guard
  {
    path: 'register',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'terms-of-use',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./pages/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule,
      ),
  },
  {
    path: 'privacy-policy',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule,
      ),
  },
  // For without layout and without guard
  {
    path: 'offline',
    data: { isPreload: true },
    loadChildren: () =>
      import('./pages/offline/offline.module').then((m) => m.OfflineModule),
  },
  {
    path: 'unavailable',
    data: { isPreload: true },
    loadChildren: () =>
      import('./pages/unavailable/unavailable.module').then(
        (m) => m.UnavailableModule,
      ),
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule,
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService, // NoPreloading // PreloadAllModules
      enableTracing: false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
