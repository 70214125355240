<dialog
  [open]="config.canShow"
  class="pop-up"
  [ngStyle]="{ display: config.canShow ? 'flex' : 'none' }"
>
  <section class="body-center confirm-container">
    <p class="text-primary">{{ config.data?.message }} <span>!</span></p>
    <div class="button-center-align form-group-g confirm-btn">
      <button class="btn-blue-outline" (click)="onClose(true)">Confirm</button>
      <button class="btn-blue-outline" (click)="onClose(false)">Cancel</button>
    </div>
  </section>
</dialog>
