import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Dialog } from '@vertel/dialog';
import { filter } from 'rxjs';

import { PortalSupportComponent } from '~components/portal-support/portal-support.component';

@Component({
  selector: 'app-universal-footer',
  templateUrl: './universal-footer.component.html',
  styleUrls: ['./universal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalFooterComponent implements OnInit {
  titlePath: string | undefined;
  isLoaded = true;
  constructor(
    private dialog: Dialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.titlePath = this.router.url.split('/').pop();
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(filter((params: Params) => params['d'] === 'true'))
      .subscribe(() => {
        this.isLoaded = false;
      });
  }
  onPortalSupport(): void {
    this.dialog.show<PortalSupportComponent>(PortalSupportComponent, {
      isCloseEnabled: true,
    });
  }
}
