import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription, filter } from 'rxjs';

import { GlobalService } from '~services/global/global.service';

@Component({
  selector: 'app-basic-header',
  templateUrl: './basic-header.component.html',
  styleUrls: ['./basic-header.component.scss'],
})
export class BasicHeaderComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  subs = new Subscription();
  isLoaded = true;
  constructor(
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(filter((params: Params) => params['d'] === 'true'))
      .subscribe(() => {
        this.isLoaded = false;
      });
    this.subs.add(
      this.globalService.isLoggedIn$.subscribe(
        (flag) => (this.isAuthenticated = flag),
        console.error,
      ),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
