import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { ServicesCardComponent } from '../standalone/services-card/services-card.component';
import { ToggleGroupComponent } from '../standalone/toggle-group/toggle-group.component';
import { HeaderComponent } from '~components/header/header.component';
import { SidebarComponent } from '~components/sidebar/sidebar.component';
import { BreadcrumbComponent } from '~components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [HeaderComponent, SidebarComponent, BreadcrumbComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatCardModule,
    ServicesCardComponent,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    ToggleGroupComponent,
    HttpClientModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
  ],
  exports: [HeaderComponent, SidebarComponent, BreadcrumbComponent],
})
export class SharedModule {}
