import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
// import { Router } from '@angular/router';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '~services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    // private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.addToken(request, sessionStorage.getItem('accessToken'));

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && sessionStorage.getItem('accessToken')) {
          // Call onRefreshToken on every 401 error to get a new token
          return this.authService.onRefreshToken().pipe(
            switchMap((newToken: any) => {
              // Update sessionStorage with the new token
              sessionStorage.setItem('accessToken', newToken.data.accessToken);
              // Retry the original request with the new token
              return next.handle(this.addToken(request, newToken.data.accessToken));
            }),
            catchError((refreshTokenError) => {
              // sessionStorage.clear();
              // this.router.navigateByUrl('/login');
              return throwError(() => refreshTokenError);
            }),
          );
        } else {
          return throwError(() => {
            console.error(error)
          });
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const AUTH_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
