import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { DialogDirective } from './dialog.directive';
import { Dialog } from './dialog.service';
import { Config } from './dialog.type';

@Component({
  selector: 'vertel-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {
  @ViewChild(DialogDirective, { static: true })
  public directive: DialogDirective | null = null;
  public config: Config = {
    data: {},
    isCloseEnabled: true,
    canShow: false,
  };
  private subs = new Subscription();
  constructor(
    private dialog: Dialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // set view ref for modal
    this.dialog.setContainerRef = this.directive?.viewContainerRef || null;

    this.subs.add(
      this.dialog.config$.subscribe((config) => {
        this.config = config;
        this.changeDetectorRef.detectChanges();
      }, console.error),
    );
  }

  public onClose(): void {
    this.dialog.hide();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
