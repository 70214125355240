import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { Config } from './confirm.type';

@Injectable()
export class Confirm {
  private onClose$ = new Subject<boolean>();
  public config$ = new BehaviorSubject<Config>({
    canShow: false,
    data: {},
  });
  constructor() {}

  public present(message: string): Observable<boolean> {
    this.config$.next({ canShow: true, data: { message } });
    document.body.style.overflow = 'hidden';
    return this.onClose$;
  }

  public close(flag: boolean): void {
    this.config$.next({ canShow: false, data: {} });
    document.body.style.overflow = 'scroll';
    this.onClose$.next(flag);
  }
}
