import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from './toaster.type';
import { Toaster } from './toaster.service';

@Component({
  selector: 'vertel-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit, OnDestroy {
  public config: Config = {
    canShow: false,
    data: {},
  };
  private subs = new Subscription();
  constructor(
    private toaster: Toaster,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.toaster.config$.subscribe((config) => {
        this.config = config;
        this.changeDetectorRef.detectChanges();
      }, console.error),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
