<dialog
  [open]="config.canShow"
  class="pop-up"
  [ngStyle]="{ display: config.canShow ? 'flex' : 'none' }"
>
  <section class="dialog-container">
    <ng-template vertelDialog></ng-template>
    <div class="close" *ngIf="config.isCloseEnabled">
      <button (click)="onClose()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="transparent"
          stroke="#626366"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </section>
</dialog>
