<footer class="netcontainerfull relative" *ngIf="this.isLoaded">
  <div class="net-container">
    <div class="footer-content">
      <div class="row">
        <div class="net l3 m2 sm12 s12">
          <div class="footer-logo">
            <a routerLink="/">
              <img
                src="assets/svgs/logo-w.svg"
                class="img-fluid"
                alt="vertel logo"
              />
            </a>
          </div>
        </div>
        <div class="net l6 m7 sm12 s12">
          <ul class="footer-link">
            <li>
              <a target="_blank" routerLink="/terms-of-use">Terms Of Use </a>
            </li>
            <li>
              <a target="_blank" routerLink="/privacy-policy"
                >Privacy Policy
              </a>
            </li>
            <li>
              <a target="_blank" href="/assets/pdfs/fair-use-policy.pdf">
                Fair use policy
              </a>
            </li>
            <li>© Vertel 2024</li>
          </ul>
        </div>
        <div class="net l3 m3 sm12 s12">
          <ul class="footer-social">
            <li>
              <button (click)="onPortalSupport()" class="footer-contact-btn">
                CONTACT US
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
