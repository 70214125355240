import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Toaster } from '@vertel/toaster';

import { AuthService } from '~services/auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class SetPasswordResolver {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toaster: Toaster,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    _state?: RouterStateSnapshot,
  ): Observable<boolean> {
    const sessionToken = route.paramMap.get('setToken');
    return this.authService.verifySessionTokenLink({ sessionToken }).pipe(
      map(({ data, message }) => {
        if (!data.status) {
          this.toaster.show(message);
          this.router.navigate(['/']).finally();
        }
        return data.status;
      }),
      catchError((_) => of(false)),
    );
  }
}
