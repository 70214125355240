/**
 * Api Service and feature
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, retry, tap } from 'rxjs/operators';

import {
  AnyType,
  ApiRes,
  ApiResWithData,
  ICardsResp,
} from '~interfaces/shared.interface';
import { Acl, IRefreshToken, LoggedIn, User } from '~interfaces/user.interface';
import { UrlService } from '~services/url/url.service';
import { GlobalService } from '~services/global/global.service';

import { Loader } from '@vertel/loader';
import { Toaster } from '@vertel/toaster';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends UrlService {
  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private loader: Loader,
    private toaster: Toaster,
  ) {
    super();
  }

  localPing(): Observable<ApiResWithData<boolean>> {
    return this.httpClient
      .get<ApiResWithData<boolean>>(this.localPingURL())
      .pipe(retry(1));
  }

  doLogin(payload: AnyType): Observable<ApiResWithData<LoggedIn>> {
    return this.httpClient
      .post<ApiResWithData<LoggedIn>>(this.loginURL, payload)
      .pipe(
        map((resp) => {
          this.globalService.setAccessToken = resp.data.accessToken;
          this.globalService.setAcl = resp.data.user.accessControlList as Acl;
          delete resp.data.user.accessControlList;
          this.globalService.setUser = resp.data.user;
          return resp;
        }),
      );
  }

  onRefreshToken(): Observable<IRefreshToken> {
    this.loader.start();
    return this.httpClient
      .put<IRefreshToken>(this.refreshTokenURL, {
        refreshToken: sessionStorage.getItem('refreshToken'),
      })
      .pipe(
        tap((data: any) => {
          this.handleTokenExpiration(data.data.tokenExpiredAt);
          sessionStorage.setItem('accessToken', data.data.accessToken); 
          this.loader.stop();
        }),
      );
  }

  sendForgetPasswordLink(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.patch<ApiRes>(this.forgotPasswordURL, payload);
  }

  verifySessionTokenLink(payload: AnyType): Observable<ApiResWithData<any>> {
    return this.httpClient.post<ApiResWithData<{ status: boolean }>>(
      this.verifySessionTokenURL,
      payload,
    );
  }

  resetPassword(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.put<ApiRes>(this.resetPasswordURL, payload);
  }

  setPassword(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.put<ApiRes>(this.setPasswordURL, payload);
  }

  getAcl(): Observable<ApiResWithData<Acl>> {
    return this.httpClient.get<ApiResWithData<Acl>>(this.aclURL).pipe(retry(1));
  }

  whoiam(): Observable<ApiResWithData<User>> {
    return this.httpClient.get<ApiResWithData<User>>(this.whoiamURL).pipe(
      map((resp) => {
        this.globalService.setAcl = resp.data.accessControlList as Acl;
        delete resp.data.accessControlList;
        this.globalService.setUser = resp.data;
        return resp;
      }),
    );
  }

  getDashboardCards() {
    return this.httpClient.get<ICardsResp>(this.dashboardCardsURL);
  }
  private timeoutId: any;
  private activityTimeout: any;

  handleTokenExpiration(tokenExpiredAt: string): void {
    const expirationDate = new Date(tokenExpiredAt);
    const currentDate = new Date();
    const timeToExpire = expirationDate.getTime() - currentDate.getTime();
  
    if (timeToExpire > 0) {
      this.resetTimeout(); // Initialize the activity timeout when the component loads
      window.addEventListener('mousemove', this.resetTimeout.bind(this));
      window.addEventListener('click', this.resetTimeout.bind(this));
      window.addEventListener('keydown', this.resetTimeout.bind(this));
    }
  }
  
  resetTimeout(): void {
    // Clear both timeouts and set up fresh ones
    clearTimeout(this.activityTimeout);
    clearTimeout(this.timeoutId);
  
    // Set warning timeout for inactivity
    this.activityTimeout = setTimeout(() => {
      this.toaster.show("Please perform some activity, or your session will expire soon.");
    }, 60000); // Show warning after 1 minute of inactivity
  
    // Set main token expiration timeout after 1 minute of inactivity
    this.timeoutId = setTimeout(() => {
      this.redirectToLogin();
    }, 60000); // 1 minute timeout for logout due to inactivity
  }
  
  redirectToLogin(): void {
    this.globalService.logout();
    this.toaster.show("Your session has expired. Please log in again.");
  }
  
}
