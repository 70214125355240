<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li
      class="breadcrumb-item"
      *ngFor="let breadcrumb of breadcrumbs; let last = last"
    >
      <a
      *ngIf="!last; else lastBreadcrumb"
      [routerLink]="[breadcrumb.url]"
      [queryParams]="breadcrumb.queryParams"
      >{{ breadcrumb.label }}
    </a>
      <ng-template #lastBreadcrumb>{{ breadcrumb.label }} {{breadcrumb.queryParams.ticketId}}</ng-template>
    </li>
  </ol>
</nav>
