import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Config } from './toaster.type';

@Injectable()
export class Toaster {
  public config$ = new BehaviorSubject<Config>({
    canShow: false,
    data: {},
  });
  constructor() {}

  public show(message: string, seconds: number = 5): void {
    this.config$.next({ canShow: true, data: { message } });
    setTimeout(() => {
      this.config$.next({ canShow: false, data: {} });
    }, 1000 * seconds);
  }
}
