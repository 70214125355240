import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertComponent } from './alert.component';
import { Alert } from './alert.service';

@NgModule({
  declarations: [AlertComponent],
  imports: [CommonModule],
  providers: [Alert],
  exports: [AlertComponent],
})
export class AlertModule {}
