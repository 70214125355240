import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

import { Dialog } from '@vertel/dialog';

import { GlobalService } from '~services/global/global.service';
import { environment } from '~environments/environment';
import { PortalSupportComponent } from '~components/portal-support/portal-support.component';

type DropdownType = 'setting' | 'notification' | 'search';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit, OnDestroy {
  public showNavList = false;
  public isDevelopment = environment.development;
  public controls: string[] = [];
  public isDropdownVisible = false;
  public dropdownType: DropdownType = 'setting';
  public readonly sdWanUrl = environment.sdWanUrl;
  private accountRecId: string | null = null;
  private subs = new Subscription();
  titlePath: string | undefined;
  isLoaded = true;
  constructor(
    private router: Router,
    private globalService: GlobalService,
    private dialog: Dialog,
    private activatedRoute: ActivatedRoute,
  ) {
    this.titlePath = this.router.url.split('/').pop();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(filter((params: Params) => params['d'] === 'true'))
      .subscribe(() => {
        this.isLoaded = false;
      });
    if (this.isLoaded) {
      this.subs.add(
        this.globalService.acl$.subscribe((acl) => {
          this.controls =
            acl?.accessControls.map(({ module }) => module.control) || [];
        }, console.error),
      );
      this.subs.add(
        this.globalService.user$.subscribe((user) => {
          this.accountRecId = user?.organizations?.accountRecId || null;
        }, console.error),
      );
    }
  }

  public get graphUtilisationUrl(): string {
    return environment.graphUtilisationUrl + this.accountRecId;
  }

  public goTo(e: MouseEvent, url: string): void {
    e.stopPropagation();
    this.router.navigate([url]).finally();
  }

  public onLogout(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.toggleDropdown(e, 'setting');
    this.globalService.logout();
  }

  public toggleDropdown(e: Event, type: DropdownType): void {
    e.stopPropagation();
    if (this.dropdownType !== type) {
      this.isDropdownVisible = false;
    }

    this.isDropdownVisible = !this.isDropdownVisible;
    this.dropdownType = type;
  }

  public onPortalSupport(): void {
    this.dialog.show<PortalSupportComponent>(PortalSupportComponent, {
      isCloseEnabled: true,
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
